@import '../../../styles/customMediaQueries.css';

.root {}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.error {
  color: var(--colorFail);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.quantityField {
  & label {
    font-size: 14px;
    margin-top: 24px;
  }
}

.patnershipHeading {
  margin-top: 30px;
}

.patnershipStartBox {
  margin-top: 28px;
}

.sendButton {
  margin-top: 40px;
}

.priceInput {
  margin-top: 24px;

  & input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
.radioWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: left;
}
.paymentPerformanceLabel {
  margin-top: 30px;
}